import {types} from 'mobx-state-tree';

import {
  AuthStore,
  AppUIStore,
  ContestStore,
  ContestSectionStore,
  ContestStageStore,
  ContestApplicationStore,
  CollectionStore,
  TemplatesStore,
  NewsStore,
  FileStore,
  ContestFormStore,
  SponsorStore,
  AnonymousStore,
  PartnerStore,
  LanguageStore,
  ManagerStore,
  TokensStore,
  PoliciesStore,
  ApplicationsManageStore,
  ResultStore,
  MedalsStore,
  ReportStore
} from 'Internal';


export const RootStore = types.model(
  'RootStore',
  {
    // domain
    authStore: types.late(() => AuthStore),
    contestStore: types.late(() => ContestStore),
    contestSectionStore: types.late(() => ContestSectionStore),
    contestStageStore: types.late(() => ContestStageStore),
    contestApplicationStore: types.late(() => ContestApplicationStore),
    collectionStore: types.late(() => CollectionStore),
    templatesStore: types.late(() => TemplatesStore),
    newsStore: types.late(() => NewsStore),
    fileStore: types.late(() => FileStore),
    contestFormStore: types.late(() => ContestFormStore),
    sponsorStore: types.late(() => SponsorStore),
    anonymousStore: types.late(() => AnonymousStore),
    partnerStore: types.late(() => PartnerStore),
    languageStore: types.late(() => LanguageStore),
    managerStore: types.late(() => ManagerStore),
    tokensStore: types.late(() => TokensStore),
    policiesStore: types.late(() => PoliciesStore),
    applicationsManageStore : types.late(() => ApplicationsManageStore),
    medalsStore: types.late(() => MedalsStore),
    reportStore: types.late(() => ReportStore),
    // ui
    appUIStore: types.late(() => AppUIStore),
    resultStore: types.late(() => ResultStore),

  }
);
