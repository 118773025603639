import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const SigninII = loadable(
  () => import('./SigninII'),
  {fallback: (<Loading/>)}
);

