import {getEnv} from 'mobx-state-tree';
import {when} from 'mobx';
import {fromPromise, FULFILLED} from 'mobx-utils';

import {
  DomainStore,
} from 'Internal';


export const LanguageStore = DomainStore.named(
  'LanguageStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/languages';

  function getAll(_contestId, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: () => {
            self.runInAction(() => {
            });
          }
        });
      }
    );

    return prom;
  }

  return {
    getAll
  };
});
