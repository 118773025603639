import {getSnapshot, types} from 'mobx-state-tree';


export const BaseModel = types.model(
  'BaseModel',
  {}
).views(self => ({
  get snap() {
    return getSnapshot(self);
  },
}));
