import React from 'react';
import * as R from 'ramda';

import {
  Signin,
  Signup,
  MainPage,
  NewsPage,
  Info,
  ContestPage,
  Contest,
  ContestCreate,
  ContestUpdate,
  ContestSections,
  ContestStages_,
  ContestApplication,
  ContestFormBuilder,
  AdminContests,
  ContestStageCreate,
  ContestStageUpdate,
  AdminNews,
  InfoCreate,
  InfoUpdate,
  ChangePassword,
  FAQPage,
  Users,
  UserUpdate,
  UserCreate,
  SectionCreate,
  SectionUpdate,
  Application,
  Partners,
  CollectionList,
  TemplatesList,
  ContestStagesUser_,
  TemplateCreate,
  TemplateUpdate,
  ConfirmMail,
  SelectPlace,
  Medals
} from 'Internal';

export function makeRoute(routes) {
  const mr = R.pipe(
    (rts) =>
      R.map(
        R.cond([
          [R.and(R.startsWith('/'), R.endsWith('/')), (r) => r.slice(1, -1)],
          [R.startsWith('/'), (r) => r.substring(1)],
          [R.endsWith('/'), (r) => r.slice(0, -1)],
          [R.T, (r) => r],
        ]),
        rts
      ),
    R.join('/'),
    (res) => R.concat('/', res)
  );
  return mr(routes);
}

export function passRouteParams(routeStr, params) {
  for (const [key, value] of Object.entries(params)) {
    routeStr = routeStr.replace(new RegExp(`:${key}`, 'i'), value);
  }
  return routeStr;
}

export const ROOT_ROUTE = makeRoute([]);
export const MODE_ROUTE = makeRoute([':mode']);
export const START_ROUTE = makeRoute(['start']);
export const PAGE404_ROUTE = makeRoute(['404']);
export const PAGE500_ROUTE = makeRoute(['500']);
export const SIGNIN_ROUTE = makeRoute(['signin']);
export const SIGNIN_II_ROUTE = makeRoute([SIGNIN_ROUTE, 'ii']);
export const SIGNUP_ROUTE = makeRoute(['signup']);
export const FAQ_ROUTE = makeRoute(['faq']);
export const NEWS_ROUTE = makeRoute(['news']);
export const TEMPLATES_ROUTE = makeRoute(['templates']);
export const COLLECTIONS_ROUTE = makeRoute(['collections']);
export const INFO_ROUTE = makeRoute([NEWS_ROUTE, ':id']);
export const CONTESTS_ROUTE = makeRoute(['contests']);
export const CONTEST_ROUTE = makeRoute(['c', ':id']);
export const CONTEST_ROUTE_WITH_KEY = makeRoute([CONTEST_ROUTE, ':tabsKey']);
export const CONTEST_ROUTE_FULL = makeRoute([CONTESTS_ROUTE, ':id']);
export const CONTEST_APPLY_ROUTE = makeRoute([CONTEST_ROUTE, 'apply', 'user']);
export const CONTESTS_ADMIN_ROUTE = makeRoute(['admin', CONTESTS_ROUTE]);
export const CONTEST_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE]);
export const CONTEST_PARTNERS_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'partners']);
export const CONTEST_SECTIONS_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'sections', ':tabsKey']);
export const CONTEST_SECTION_NEW_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'section']);
export const CONTEST_SECTION_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'section', ':sectionId']);
export const CONTEST_APPLICATION_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'application', ':applicationId']);
export const CONTEST_STAGES_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'stages']);
export const CONTEST_STAGE_NEW_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'stage']);
export const CONTEST_STAGE_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'stages', ':stageId']);
export const CONTEST_NEW_ADMIN_ROUTE = makeRoute(['admin', 'contest']);
export const CONTEST_FORM_BUILDER_ADMIN_ROUTE = makeRoute(['admin', CONTEST_ROUTE, 'form']);
export const CONTEST_STAGES_ROUTE = makeRoute([CONTEST_ROUTE, 'stages']);
export const NEWS_ADMIN_ROUTE = makeRoute(['admin', NEWS_ROUTE]);
export const INFO_ADMIN_ROUTE = makeRoute(['admin', INFO_ROUTE]);
export const INFO_NEW_ADMIN_ROUTE = makeRoute(['admin', 'info', ':contestBinding']);
export const TEMPLATES_NEW_ADMIN_ROUTE = makeRoute(['admin', TEMPLATES_ROUTE, ':id']);
export const TEMPLATES_UPDATE_ADMIN_ROUTE = makeRoute(['admin', TEMPLATES_ROUTE, 'update', ':id', ':template']);
export const CHANGE_PASSWORD_ROUTE = makeRoute(['admin', ':id', 'change_password']);
export const USERS_ADMIN_ROUTE = makeRoute(['admin', 'users']);
export const USER_UPDATE_ADMIN_ROUTE = makeRoute([USERS_ADMIN_ROUTE, ':id']);
export const USER_CREATE_ADMIN_ROUTE = makeRoute(['admin', 'user']);
export const CONFIRM_MAIL_ROUTE = makeRoute(['tokens', ':token']);
export const SELECT_PLACE_ROUTE = makeRoute(['PlaceChoices', ':token']);
export const CONTEST_SECTION_ROUTE = makeRoute([CONTEST_ROUTE, 'compets']);
export const MEDALS = makeRoute(['medals']);

export const routes = [
  {
    key: 'home-view',
    name: 'Home',
    path: ROOT_ROUTE,
    exact: true,
    render: (props) => <MainPage {...props} />,
  },
  {
    key: 'signin-view',
    name: 'Sign in',
    path: SIGNIN_ROUTE,
    exact: true,
    render: (props) => <Signin {...props} />,
  },
  {
    key: 'signup-view',
    name: 'Sign up',
    path: SIGNUP_ROUTE,
    exact: true,
    render: (props) => <Signup {...props} />,
  },
  {
    key: 'faq-view',
    name: '',
    path: FAQ_ROUTE,
    exact: true,
    render: (props) => <FAQPage {...props} />,
  },
  {
    key: 'news-view',
    name: 'News',
    path: NEWS_ROUTE,
    exact: true,
    render: (props) => <NewsPage {...props} />,
  },
  {
    key: 'collections-view',
    name: 'Collections',
    path: COLLECTIONS_ROUTE,
    exact: true,
    render: (props) => <CollectionList {...props} />,
  },
  {
    key: 'templates-view',
    name: 'Collections',
    path: TEMPLATES_ROUTE,
    exact: true,
    render: (props) => <TemplatesList {...props} />,
  },
  {
    key: 'info-view',
    name: 'Info',
    path: INFO_ROUTE,
    exact: true,
    render: (props) => <Info {...props} />,
  },
  {
    key: 'contests-view',
    name: 'Contests',
    path: CONTESTS_ROUTE,
    exact: true,
    render: (props) => <ContestPage {...props} />,
  },
  {
    key: 'con-view',
    name: 'Contest',
    path: CONTEST_ROUTE_WITH_KEY,
    exact: true,
    render: (props) => <Contest {...props} />,
  },
  {
    key: 'con-view',
    name: 'ContestFull',
    path: CONTEST_ROUTE_FULL,
    exact: true,
    render: (props) => <Contest {...props} />,
  },
  {
    key: 'apply-view',
    name: 'ContestApplication',
    path: CONTEST_APPLY_ROUTE,
    exact: true,
    render: (props) => <ContestApplication {...props} />,
  },
  {
    key: 'newcontest-view',
    name: 'NewContest',
    path: CONTEST_NEW_ADMIN_ROUTE,
    exact: true,
    render: (props) => <ContestCreate {...props} />,
  },
  {
    key: 'editcontest-view',
    name: 'EditContest',
    path: CONTEST_ADMIN_ROUTE,
    exact: true,
    render: (props) => <ContestUpdate {...props} />,
  },
  {
    key: 'contestsections-view',
    name: '',
    path: CONTEST_SECTIONS_ADMIN_ROUTE,
    exact: true,
    render: (props) => <ContestSections {...props} />,
  },
  {
    key: 'conteststages-view',
    name: '',
    path: CONTEST_STAGES_ADMIN_ROUTE,
    exact: true,
    render: (props) => <ContestStages_ {...props} />,
  },
  {
    key: 'contest-stages-user-view',
    name: '',
    path: CONTEST_STAGES_ROUTE,
    exact: true,
    render: (props) => <ContestStagesUser_ {...props} />,
  },
  {
    key: 'contest-formbuilder-view',
    name: '',
    path: CONTEST_FORM_BUILDER_ADMIN_ROUTE,
    exact: true,
    render: (props) => <ContestFormBuilder {...props} />,
  },
  {
    key: 'admin-contests-view',
    name: '',
    path: CONTESTS_ADMIN_ROUTE,
    exact: true,
    render: (props) => <AdminContests {...props} />,
  },
  {
    key: 'admin-contest-section-new-view',
    name: '',
    path: CONTEST_SECTION_NEW_ADMIN_ROUTE,
    exact: true,
    render: (props) => <SectionCreate {...props} />,
  },
  {
    key: 'admin-contest-section-update-view',
    name: '',
    path: CONTEST_SECTION_ADMIN_ROUTE,
    exact: true,
    render: (props) => <SectionUpdate {...props} />,
  },
  {
    key: 'admin-contest-stage-new-view',
    name: '',
    path: CONTEST_STAGE_NEW_ADMIN_ROUTE,
    exact: true,
    render: (props) => <ContestStageCreate {...props} />,
  },
  {
    key: 'admin-contest-stage-update-view',
    name: '',
    path: CONTEST_STAGE_ADMIN_ROUTE,
    exact: true,
    render: (props) => <ContestStageUpdate {...props} />,
  },
  {
    key: 'admin-news-view',
    name: 'News',
    path: NEWS_ADMIN_ROUTE,
    exact: true,
    render: (props) => <AdminNews {...props} />,
  },
  {
    key: 'info-create-view',
    name: 'News',
    path: INFO_NEW_ADMIN_ROUTE,
    exact: true,
    render: (props) => <InfoCreate {...props} />,
  },
  {
    key: 'template-create-view',
    name: 'Template',
    path: TEMPLATES_NEW_ADMIN_ROUTE,
    exact: true,
    render: (props) => <TemplateCreate {...props} />,
  },
  {
    key: 'template-update-view',
    name: 'Template',
    path: TEMPLATES_UPDATE_ADMIN_ROUTE,
    exact: true,
    render: (props) => <TemplateUpdate {...props} />,
  },
  {
    key: 'info-update-view',
    name: 'News',
    path: INFO_ADMIN_ROUTE,
    exact: true,
    render: (props) => <InfoUpdate {...props} />,
  },
  {
    key: 'change-pass-view',
    name: '',
    path: CHANGE_PASSWORD_ROUTE,
    exact: true,
    render: (props) => <ChangePassword {...props} />,
  },
  {
    key: 'users-view',
    name: '',
    path: USERS_ADMIN_ROUTE,
    exact: true,
    render: (props) => <Users {...props} />,
  },
  {
    key: 'user-update-view',
    name: '',
    path: USER_UPDATE_ADMIN_ROUTE,
    exact: true,
    render: (props) => <UserUpdate {...props} />,
  },
  {
    key: 'user-create-view',
    name: '',
    path: USER_CREATE_ADMIN_ROUTE,
    exact: true,
    render: (props) => <UserCreate {...props} />,
  },
  {
    key: 'application-update-view',
    name: '',
    path: CONTEST_APPLICATION_ADMIN_ROUTE,
    exact: true,
    render: (props) => <Application {...props} />,
  },
  {
    key: 'partners-view',
    name: '',
    path: CONTEST_PARTNERS_ADMIN_ROUTE,
    exact: true,
    render: (props) => <Partners {...props} />,
  },
  {
    key: 'confirm-mail',
    name: '',
    path: CONFIRM_MAIL_ROUTE,
    exact: true,
    render: (props) => <ConfirmMail {...props} />,
  },
  {
    key: 'select-place',
    name: '',
    path: SELECT_PLACE_ROUTE,
    exact: true,
    render: (props) => <SelectPlace {...props} />,
  },
  {
    key: 'medals-view',
    name: 'Medals',
    path: MEDALS,
    exact: true,
    render: (props) => <Medals {...props} />,
  },
];
