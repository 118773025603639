import React from 'react';
import loadable from '@loadable/component';

import { Loading } from 'Internal';

export const ContestCreate = loadable(() => import('./ContestCreate'), { fallback: <Loading /> });

export const ContestUpdate = loadable(() => import('./ContestUpdate'), { fallback: <Loading /> });

export const ContestSections = loadable(() => import('./Sections/ContestSections'), { fallback: <Loading /> });

export const SectionForm = loadable(() => import('./Sections/SectionForm'), { fallback: <Loading /> });

export const SectionCreate = loadable(() => import('./Sections/SectionCreate'), { fallback: <Loading /> });

export const SectionUpdate = loadable(() => import('./Sections/SectionUpdate'), { fallback: <Loading /> });

export const CompetitorsList = loadable(() => import('./Sections/CompetitorsList'), { fallback: <Loading /> });

export const ContestForm = loadable(() => import('./ContestForm'), { fallback: <Loading /> });

export const ContestFormBuilder = loadable(() => import('./FormBuilder/FormBuilder'), { fallback: <Loading /> });

export const FormList = loadable(() => import('./FormBuilder/BuilderFields'), { fallback: <Loading /> });

export const FormModal = loadable(() => import('./FormBuilder/BuilderModal'), { fallback: <Loading /> });

export const AdminContestList = loadable(() => import('./ContestList'), { fallback: <Loading /> });

export const AdminContests = loadable(() => import('./Contests'), { fallback: <Loading /> });

export const ContestStageForm = loadable(() => import('./Stages/StageForm'), { fallback: <Loading /> });

export const ContestStageCreate = loadable(() => import('./Stages/StageCreate'), { fallback: <Loading /> });

export const ContestStageUpdate = loadable(() => import('./Stages/StageUpdate'), { fallback: <Loading /> });

export const StageListActions = loadable(() => import('./Stages/StageListActions'), { fallback: <Loading /> });

export const ContestStageList = loadable(() => import('./Stages/StageList'), { fallback: <Loading /> });

export const ContestStages_ = loadable(() => import('./Stages/Stages'), { fallback: <Loading /> });

export const Application = loadable(() => import('./Application'), { fallback: <Loading /> });

export const Partners = loadable(() => import('./Partners/Partners'), { fallback: <Loading /> });

export const WinnersPage = loadable(() => import('./Winners/Winners'), { fallback: <Loading /> });

export const ReportPage = loadable(() => import('./Report/Report'), { fallback: <Loading /> });