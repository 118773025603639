import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {
  DomainStore,
  getCurrentLangId
} from 'Internal';


export const ContestFormStore = DomainStore.named(
  'ContestFormStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = 'forms';

  function getContests(_params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/contests`, {
        params: _params,
      })
    );

    return prom;
  }
  
  function getTypes(_params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/fields/types`, {
        params: _params,
      })
    );

    return prom;
  }

  function getCollections(_params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/collections`, {
        params: _params,
      })
    );

    return prom;
  }

  function get(_contestId, _params = {}) {
    _params.contestId = _contestId;
    const prom = fromPromise(
      apiV1.get(`/${serviceUrl}`, {
        params: _params,
      })
    );

    return prom;
  }

  function create(_data, _params = {}) {

    const prom = fromPromise( 
      apiV1.post(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function update(_data, _params = {}) {

    const prom = fromPromise(
      apiV1.put(`${serviceUrl}`, _data, {
        params: _params,
      })
    );
    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );
    return prom;
  }

  function addFields(_id, _data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/${_id}/fields`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function orderingFields(_data){
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}/Fields/Ordering`, _data)
    );

    return prom;
  }

  return {
    getContests,
    getTypes,
    getCollections,
    get,
    create,
    update,
    remove,
    addFields,
    orderingFields
  };
});