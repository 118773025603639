import {getEnv} from 'mobx-state-tree';
import {when} from 'mobx';
import {fromPromise, FULFILLED} from 'mobx-utils';

import {
  DomainStore,
  getCurrentLangId,
} from 'Internal';


export const ContestApplicationStore = DomainStore.named(
  'ContestApplicationStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/applications';

  function getAll(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: () => {
            self.runInAction(() => {
            });
          }
        });
      }
    );

    return prom;
  }

  function getApplicationForm(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/form`, {
        params: _params,
      })
    );

    return prom;
  }

  function getFormTitles(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/form/titles`, {
        params: _params,
      })
    );

    return prom;
  }

  function getStatuses(_params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/statuses`, {
        params: _params,
      })
    );

    return prom;
  }

  function get(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}&langId=${getCurrentLangId()}`, {
        params: _params,
      })
    );

    return prom;
  }

  function create(_data = {}, _params = {}) {

    const prom = fromPromise(
      apiV1.post(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function update(_data = {}, _params = {}) {
    
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function showField(_id) {
    const prom = fromPromise(
      apiV1.patch(`${serviceUrl}/Form/Titles/${_id}/Show`)
    );

    return prom;
  }

  function hideField(_id) {
    const prom = fromPromise(
      apiV1.patch(`${serviceUrl}/Form/Titles/${_id}/Hide`)
    );

    return prom;
  }

  function deleteApplication(_id) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`)
    );

    return prom;
  }

  function generateLogin(data) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/Access`, data)
    );

    return prom;
  }

  function sendLink(data) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/SendLink`, data)
    );

    return prom;
  }

  function changeStatus(data) {
    const prom = fromPromise(
      apiV1.patch(`${serviceUrl}/Statuses`, data)
    );

    return prom;
  }

  function getExcelApplication(contestId) {
    return  fromPromise(apiV1.get(`${serviceUrl}/excel`, {params: {contestId, langId: getCurrentLangId()}}));
  }

  function importLoginsPasswords(_data = {}, _params = {}) {

    const prom = fromPromise(
      apiV1.put(`${serviceUrl}/Access`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function importLoginsPasswordsExcel(_data = {}, _params = {}) {

    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/InsertAccess`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function getPassLogin(_id, stageId, _params = {}) {
    _params.stageId = stageId;
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}/Access`, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    getStatuses,
    getApplicationForm,
    getFormTitles,
    getAll,
    get,
    create,
    update,
    remove,
    showField,
    hideField,
    deleteApplication,
    generateLogin,
    sendLink,
    changeStatus,
    getExcelApplication,
    importLoginsPasswords,
    getPassLogin,
    importLoginsPasswordsExcel
  };
});
