import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {DomainStore} from 'Internal';

export const SponsorStore = DomainStore.named('SponsorStore').actions((self) => {
  const {apiV1} = getEnv(self);
  const serviceUrl = '/sponsors';

  function getAll(_params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );

    return prom;
  }

  function get(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function create(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}`, _data, {
        params: _params,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    );

    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    getAll,
    get,
    create,
    remove,
  };
});
