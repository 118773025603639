import {types} from 'mobx-state-tree';


export const BaseStore = types.model(
  'BaseStore'
).actions(() => {

  function runInAction(fn) {
    return fn();
  }

  return {
    runInAction,
  };
});
