import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {
  DomainStore,
} from 'Internal';


export const FileStore = DomainStore.named(
  'FileStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/savedfiles';

  function get(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function update(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}`, _data, {
        params: _params,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    );

    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    get,
    update,
    remove,
  };
});
