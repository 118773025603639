import {getSnapshot, types} from 'mobx-state-tree';
import {when} from 'mobx';
import {PENDING, REJECTED} from 'mobx-utils';

import {BaseStore} from 'Internal';


export const DomainStore = BaseStore.named(
  'DomainStore'
).props({
  _errs: types.optional(
    types.array(types.string),
    []
  ),
}).actions(self => {

  function handleReset(prom) {
    when(
      () => prom.state === PENDING,
      () => {
        prom.case({
          pending: () => {
            self.runInAction(() => {
              self._errs = [];
            });
          }
        });
      }
    );
  }

  function handleErr(prom) {
    when(
      () => prom.state === REJECTED,
      () => {
        prom.case({
          rejected: () => {
            self.runInAction(() => {
            });
          }
        });
      }
    );
  }

  return {
    handleReset,
    handleErr,
  };
}).views(self => ({
  get errs() {
    return getSnapshot(self._errs);
  }
}));
