import { getEnv } from 'mobx-state-tree';
import { fromPromise } from 'mobx-utils';

import { DomainStore, getCurrentLangId } from 'Internal';

export const ApplicationsManageStore = DomainStore.named('ApplicationsManageStore').actions((self) => {
  const { apiV1 } = getEnv(self);
  const serviceUrl = '/stages';

  function genAccessExcel(id, params = {}) {
    params.langId = getCurrentLangId();
    const prom = fromPromise(apiV1.get(`${serviceUrl}/places/${id}/genAccess/excel`, { params }));

    return prom;
  }

  function importAccessExcel(data) {
    return fromPromise(apiV1.post('/applications/batchInsert', data));
  }

  function importAccessExcelOneStep(data) {
    return fromPromise(apiV1.post('/applications/SmartImport/StepOne', data));
  }

  function importAccessExcelTwoStep(data) {
    return fromPromise(apiV1.post('/applications/SmartImport/StepTwo', data));
  }

  function compareExcellStepThree(data) {
    return fromPromise(apiV1.post('/applications/SmartImport/StepThree', data));
  }

  function addApplication(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post('/applications/', _data, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    genAccessExcel,
    importAccessExcel,
    importAccessExcelOneStep,
    importAccessExcelTwoStep,
    compareExcellStepThree,
    addApplication,
  };
});
