import {types} from 'mobx-state-tree';

import {
  BaseModel,
} from 'Internal';


export const AuthModel = BaseModel.named(
  'AuthModel'
).props({
  accessToken: types.maybeNull(types.string),
  refreshToken: types.maybeNull(types.string),
  roleName: types.maybeNull(types.string),
  username: types.maybeNull(types.string)
});
