import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {
  DomainStore,
  getCurrentLangId
} from 'Internal';


export const TemplatesStore = DomainStore.named(
  'TemplatesStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/LetterTemplates';

  function getAll(_id, _params = {}) {
    _params.contestId = _id;
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getTemplateById(_id, _params = {}) {
    _params.contestId = _id;
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function create(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function update(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function sendMailEveryone(_data = {}) {

    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/Send/Stages`, _data)
    );

    return prom;
  }

  function getStagesForTemplates(_id, _params = {}) {
    _params.contestId = _id;
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }


  function getStagesList(_id, _params = {}) {
    _params.contestId = _id;
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/Send/List`, {
        params: _params,
      })
    );

    return prom;
  }

  function getStagesForApp(_appId, _params = {}) {
    _params.appId = _appId;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/Send/Stages`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAllKeys(contestId, _params = {}) {
    _params.contestId = contestId;

    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/Keys`, {
        params: _params,
      })
    );

    return prom;
  }

  function sendTemplates(data) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/Send/Apps`, data)
    );

    return prom;
  }

  function postLinkAllParticipants(_data) {
    const prom = fromPromise(apiV1.post(`Stages/SendLink`, _data));

    return prom;
  }

  return {
    getAll,
    getTemplateById,
    create,
    update,
    remove,
    getStagesForTemplates,
    getStagesList,
    getStagesForApp,
    getAllKeys,
    sendTemplates,
    postLinkAllParticipants,
    sendMailEveryone
  };
});
