import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const Page404 = loadable(
  () => import('./Page404'),
  {fallback: (<Loading/>)}
);
