import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const UserForm = loadable(
  () => import('./UserForm'),
  {fallback: (<Loading/>)}
);

export const UserUpdate = loadable(
  () => import('./UserUpdate'),
  {fallback: (<Loading/>)}
);

export const UserCreate = loadable(
  () => import('./UserCreate'),
  {fallback: (<Loading/>)}
);

export const UserListActions = loadable(
  () => import('./UserListActions'),
  {fallback: (<Loading/>)}
);

export const UserList = loadable(
  () => import('./UserList'),
  {fallback: (<Loading/>)}
);

export const Users = loadable(
  () => import('./Users'),
  {fallback: (<Loading/>)}
);
