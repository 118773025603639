import { getEnv, types } from 'mobx-state-tree';
import { when } from 'mobx';
import { fromPromise, FULFILLED } from 'mobx-utils';

import {
  AUTH_KEY,
  DomainStore,
  AuthModel
} from 'Internal';


export const AuthStore = DomainStore.named(
  'AuthStore'
).props({
  _authData: types.optional(
    types.late(() => AuthModel),
    {}
  ),
}).actions(self => {
  const { apiV1, localStg } = getEnv(self);
  const serviceUrl = '/token';

  function signIn(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/access`, _data, {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: (resp) => {
            self.runInAction(() => {
              self._authData = resp.data;
            });
          }
        });
      }
    );

    return prom;
  }

  function refreshToken(_data = {}, _params = {}) {
    self._authData = {
      accessToken: null,
      refreshToken: null,
      roleName: null,
      username: null
    };

    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/refresh`, _data, {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: (resp) => {
            self.runInAction(() => {
              self._authData = resp.data;
            });
          }
        });
      }
    );

    return prom;
  }

  function signOut() {
    self.runInAction(() => {
      self._authData = AuthModel.create();
    });
  }

  function signUp(_data = {}, _params = {}) {
    return register(_data, _params);
  }

  function register(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/register`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function get(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}/user`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAll(_params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/users`, {
        params: _params,
      })
    );

    return prom;
  }

  function update(_id, _data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}/${_id}/user`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function getCurrent(_params = {}) {
    const prom = fromPromise(
      apiV1.get('/users/current', {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: (resp) => {
            self.runInAction(() => {
              self._authData = {
                ...self._authData,
                username: resp.data.userName
              };
            });
          }
        });
      }
    );



    return prom;
  }

  function afterCreate() {
    localStg.getItem(AUTH_KEY).then(
      (val) => {
        if (!val) {
          localStg.setItem(AUTH_KEY, JSON.stringify({
            _authData: AuthModel.create(),
          }));
        }
      },
      () => {
      },
    );
  }

  function changePassword(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.patch('/Users/ChangePassword', _data, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    refreshToken,
    signIn,
    signOut,
    signUp,
    changePassword,
    get,
    getAll,
    getCurrent,
    update,
    register,
    afterCreate,
  };
}).views(self => ({
  get authData() {
    return self._authData.snap;
  }
}));
