import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {
  DomainStore
} from 'Internal';


export const ReportStore = DomainStore.named(
  'ReportStore'
).actions(self => {

  const {apiV1} = getEnv(self);

	function getReport(_id, _params = {}) {
    _params.contestId = _id;

    const prom = fromPromise(
      apiV1.get('/MailGroups', {
        params: _params,
      })
    );

    return prom;
  }

  return {
		getReport
  };
});