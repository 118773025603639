import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const Competitors = loadable(
  () => import('./Competitors'),
  {fallback: (<Loading/>)}
);

export const Contest = loadable(
  () => import('./Contest'),
  {fallback: (<Loading/>)}
);

export const Contests = loadable(
  () => import('./Contests'),
  {fallback: (<Loading/>)}
);

export const ContestStagesUser_ = loadable(
  () => import('./Stages/Stages'),
  {fallback: (<Loading/>)}
);

export const StagesList_ = loadable(
  () => import('./Stages/StagesList'),
  {fallback: (<Loading/>)}
);

export const ContestApplication = loadable(
  () => import('./ContestApplication'),
  {fallback: (<Loading/>)}
);

export const ContestPage = loadable(
  () => import('./ContestPage'),
  {fallback: (<Loading/>)}
);
