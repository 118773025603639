import {getEnv} from 'mobx-state-tree';
import {when} from 'mobx';
import {fromPromise, FULFILLED} from 'mobx-utils';

import {
  DomainStore,
  getCurrentLangId
} from 'Internal';


export const MedalsStore = DomainStore.named(
  'MedalsStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/medals';

  function getContests(_params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/contests`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAll(_id, _params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: () => {
            self.runInAction(() => {
            });
          }
        });
      }
    );

    return prom;
  }

  function get(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function create(_data = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}`, _data)
    );

    return prom;
  }

  function update(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function addElement(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/elements`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    getContests,
    getAll,
    get,
    create,
    update,
    addElement,
    remove,
  };
});
