import axios from 'axios';
import Qs from 'qs';

import {
  API_V1_URL,
} from 'Internal';


export const API_V1_REQ = axios.create({
  baseURL: API_V1_URL,
  maxContentLength: 2000,
  paramsSerializer(params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      allowDots: true,
    });
  },
});
