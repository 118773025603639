import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';

export const FAQ = loadable(
  () => import('./FAQ'),
  {fallback: (<Loading/>)}
);

export const FAQPage = loadable(
  () => import('./FAQPage'),
  {fallback: (<Loading/>)}
);
