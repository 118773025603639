import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';

export const TemplatesList = loadable(
  () => import('./TemplatesList'),
  {fallback: (<Loading/>)}
);

export const TemplatesFeed = loadable(
  () => import('./TemplatesFeed'),
  {fallback: (<Loading/>)}
);

export const TemplateCreate = loadable(
  () => import('./TemplateCreate'),
  {fallback: (<Loading/>)}
);

export const TemplatesForm = loadable(
  () => import('./TemplatesForm'),
  {fallback: (<Loading/>)}
);

export const TemplateUpdate = loadable(
  () => import('./TemplatesUpdate'),
  {fallback: (<Loading/>)}
);