import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const DefaultLayout = loadable(
  () => import('./DefaultLayout'),
  {
    fallback: (<Loading/>),
  }
);
