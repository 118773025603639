import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const Intro = loadable(
  () => import('./Intro'),
  {fallback: (<Loading/>)}
);

export const UpcomingCompetitions = loadable(
  () => import('./UpcomingCompetitions'),
  {fallback: (<Loading/>)}
);

export const NewsPart = loadable(
  () => import('./NewsPart'),
  {fallback: (<Loading/>)}
);

export const Sponsors = loadable(
  () => import('./Sponsors'),
  {fallback: (<Loading/>)}
);

export const MainPage = loadable(
  () => import('./MainPage'),
  {fallback: (<Loading/>)}
);
