import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {
  DomainStore
} from 'Internal';


export const PoliciesStore = DomainStore.named(
  'PoliciesStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/FieldPolicies';


  function getPolicyFields(_id, _params = {}) {
		_params.contestId = _id;

    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );
    return prom;
  }

	function getAppFieldsByPolicy(_id, _params = {}) {
		_params.id = _id;

    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function addPolicyField(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

	function addField(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/Fields`, _data, {
        params: _params,
      })
    );

    return prom;
  }


  function deleteField(fieldId, policyId) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/Fields`, {data: {fieldId, policyId}})
    );

    return prom;
  }


  return {
		getPolicyFields,
		getAppFieldsByPolicy,
		addPolicyField,
		addField,
		deleteField
  };
});
