import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const UserNav = loadable(
  () => import('./UserNav'),
  {
    fallback: null,
  }
);

export const LangNav = loadable(
  () => import('./LangNav'),
  {
    fallback: null,
  }
);

export const DefaultHeader = loadable(
  () => import('./DefaultHeader'),
  {
    fallback: (<Loading/>),
  }
);
