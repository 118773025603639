import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {
  DomainStore, 
	getCurrentLangId
} from 'Internal';


export const ResultStore = DomainStore.named(
  'ResultStore'
).actions(self => {

  const {apiV1} = getEnv(self);

	function postResult(_data = {}) {
    return fromPromise(apiV1.post('/Results/Import/Excel', _data));
  }

	function getResults(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get('/Groups', {
        params: _params,
      })
    );

    return prom;
  }

  function getResultsUsers(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get('/Groups/Anon', {
        params: _params,
      })
    );

    return prom;
  }

  function create(_data = {}) {
    const prom = fromPromise(
      apiV1.post('/Groups/Distribute', _data)
    );

    return prom;
  }

  function reallocate(_id, _params = {}, _data = {}) {
    _params.groupId = _id;
    const prom = fromPromise(
      apiV1.post('/Groups/Reallocation', _data, {
        params: _params,
      })
    );

    return prom;
  }

  function update(_data = {}) {
    const prom = fromPromise(
      apiV1.put('/Results', _data)
    );
    return prom;
  }

  return {
    postResult,
		getResults,
    getResultsUsers,
    create,
    update,
    reallocate
  };
});
