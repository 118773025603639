import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';


export const CollectionList = loadable(
  () => import('./CollectionList'),
  {fallback: (<Loading/>)}
);
