import React from 'react';
import loadable from '@loadable/component';

import {Loading} from 'Internal';

export const NewsFeed = loadable(
  () => import('./NewsFeed'),
  {fallback: (<Loading/>)}
);

export const NewsContestFeed = loadable(
  () => import('./NewsContestFeed'),
  {fallback: (<Loading/>)}
);

export const Info = loadable(
  () => import('./Info'),
  {fallback: (<Loading/>)}
);

export const NewsPage = loadable(
  () => import('./NewsPage'),
  {fallback: (<Loading/>)}
);

export const NewsForContest = loadable(
  () => import('./NewsForContest'),
  {fallback: (<Loading/>)}
);