import {getEnv} from 'mobx-state-tree';
import {when} from 'mobx';
import {fromPromise, FULFILLED} from 'mobx-utils';

import {
  DomainStore
} from 'Internal';

export const TokensStore = DomainStore.named('TokensStore').actions(self => {
  const {apiV1} = getEnv(self);
  const serviceUrl = '/Tokens';

  function checkToken(token) {
    const prom = fromPromise(apiV1.delete(`${serviceUrl}`, {data: {token}}));
    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: () => {
            self.runInAction(() => {
            });
          }
        });
      }
    );

    return prom;
  }

  return {checkToken};
});
