import React from 'react';
import loadable from '@loadable/component';

import { Loading } from 'Internal';

export const NewsForm = loadable(() => import('./NewsForm'), { fallback: <Loading /> });

export const InfoCreate = loadable(() => import('./InfoCreate'), { fallback: <Loading /> });

export const InfoUpdate = loadable(() => import('./InfoUpdate'), { fallback: <Loading /> });

export const NewsListActions = loadable(() => import('./NewsListActions'), { fallback: <Loading /> });

export const AdminNewsFeed = loadable(() => import('./NewsFeed'), { fallback: <Loading /> });

export const AdminNews = loadable(() => import('./News'), { fallback: <Loading /> });
