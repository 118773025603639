// modes
export const PUBLIC_MODE = 'app';
export const PRIVATE_MODE = 'Admin';

// collections
export const COLLECTIONS = 'collections';

// user roles
export const ADMIN_ROLE = 'Admin';
export const MANAGER_ROLE = 'Manager';

// lang
export const RU_RU = 'ru-RU';
export const KG_KG = 'kg-KG';
export const EN_US = 'en-US';
export const DEFAULT_LANG = RU_RU;
export const LANGUAGES = [
  {id: 1, name: 'Русский', code: RU_RU},
  {id: 2, name: 'English', code: EN_US},
  {id: 3, name: 'Кыргызча', code: KG_KG}
];

// other
export const UNAUTH_ERROR = 401;
export const SERVER_ERROR = 500;
export const FIELD_NUMBER = 1;
export const FIELD_TEXT = 2;
export const FIELD_DATETIME = 3;
export const FIELD_SELECT = 5;
export const COMPETITORS_MODAL_ACTIONS = {
  sendLogin : 'SendLogin',
  generateLogin : 'GenerateLogin',
  deleteApplication : 'DeleteApplication',
  sendLink : 'SendLink',
  importPassLogin: 'ImportPassLogin'
};
export const PAGE_SIZE = 20;
export const PAGE_SIZE_REPORT = 15;

//pages
export const ADMIN_APPLICATION_TABLE = 'ADMIN_APPLICATION_TABLE';
export const USER_APPLICATION_TABLE = 'USER_APPLICATION_TABLE';
export const REPORT_TABLE = 'REPORT_TABLE';