import {getEnv, getSnapshot, types} from 'mobx-state-tree';
import {when} from 'mobx';
import {fromPromise, FULFILLED} from 'mobx-utils';

import {
  DomainStore,
  ContestModel,
  getCurrentLangId
} from 'Internal';


export const ContestStore = DomainStore.named(
  'ContestStore'
).props({
  _items: types.optional(
    types.array(types.late(() => ContestModel)),
    []
  ),
}).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/contests';

  function getAll(_params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: (resp) => {
            self.runInAction(() => {
              self._items = resp.data.items;
            });
          }
        });
      }
    );

    return prom;
  }

  function get(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getByLangId(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}&langId=${getCurrentLangId()}`, {
        params: _params,
      })
    );

    return prom;
  }

  function create(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function addImage(_id, _data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/${_id}/images`, _data, {
        params: _params,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    );

    return prom;
  }

  function update(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function hide(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.patch(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    getAll,
    get,
    getByLangId,
    addImage,
    create,
    update,
    remove,
    hide
  };
}).views(self => ({
  get items() {
    return getSnapshot(self._items);
  }
}));
