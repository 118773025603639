import {getEnv} from 'mobx-state-tree';
import {fromPromise} from 'mobx-utils';

import {
  DomainStore,
  getCurrentLangId,
} from 'Internal';

export const AnonymousStore = DomainStore.named('AnonymousStore').actions((self) => {
  const {apiV1} = getEnv(self);
  const serviceUrl = '/anonymous';

  function getAllContests(_params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/contests`, {
        params: _params,
      })
    );

    return prom;
  }

  function getContest(_id, _params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/contests/${_id}&langId=${getCurrentLangId()}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAllNews(_params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/news`, {
        params: _params,
      })
    );

    return prom;
  }

  function getNews(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/news/${_id}&langId=${getCurrentLangId()}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAllSections(_id, _params = {}) {
    _params.langId = getCurrentLangId();
    _params.contestId = _id;
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/sections`, {
        params: _params,
      })
    );

    return prom;
  }

  function getSection(_id, _params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/sections/${_id}&langId=${getCurrentLangId()}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAllSponsors(_params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/sponsors`, {
        params: _params,
      })
    );

    return prom;
  }

  function getSponsor(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/sponsors/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAllPartners(_id, _params = {}) {
    _params.contestId = _id;
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/partners`, {
        params: _params,
      })
    );

    return prom;
  }

  function getPartner(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/partners/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getFormTitlesAnonymous(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/applications/form/titles`, {
        params: _params,
      })
    );

    return prom;
  }

  function getApplications(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/applications`, {
        params: _params,
      })
    );

    return prom;
  }

  function getApplicationForm(_id, _params = {}) {
    _params.contestId = _id;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/applications/form`, {
        params: _params,
      })
    );

    return prom;
  }

  function addApplication(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/applications`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function getStagesByContestId(_contestId, _params = {}) {
    _params.contestId = _contestId;
    _params.langId = getCurrentLangId();

    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/stages`, {
        params: _params,
      })
    );

    return prom;
  }

  function getStageByToken(_token, _params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/Stages/${_token}`, {
        params: _params,
      })
    );

    return prom;
  }

  function selectPlaceByToken(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.patch(`${serviceUrl}/Tokens`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  return {
    addApplication,
    getApplicationForm,
    getApplications,
    getFormTitlesAnonymous,
    getAllContests,
    getContest,
    getAllNews,
    getNews,
    getAllSections,
    getSection,
    getAllSponsors,
    getSponsor,
    getAllPartners,
    getPartner,
    getStagesByContestId,
    getStageByToken,
    selectPlaceByToken
  };
});
