import {types} from 'mobx-state-tree';

import {
  BaseModel,
} from 'Internal';


export const ContestModel = BaseModel.named(
  'ContestModel'
).props({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  image_path: types.maybeNull(types.string)
});
