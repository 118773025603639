import {getEnv} from 'mobx-state-tree';
import {when} from 'mobx';
import {fromPromise, FULFILLED} from 'mobx-utils';

import {
  DomainStore,
  getCurrentLangId
} from 'Internal';


export const ContestStageStore = DomainStore.named(
  'ContestStageStore'
).actions(self => {

  const {apiV1} = getEnv(self);
  const serviceUrl = '/stages';

  function getContests(_params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/contests`, {
        params: _params,
      })
    );

    return prom;
  }

  function getAll(_contestId, _params = {}) {
    _params.contestId = _contestId;
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}`, {
        params: _params,
      })
    );

    when(
      () => prom.state === FULFILLED,
      () => {
        prom.case({
          fulfilled: () => {
            self.runInAction(() => {
            });
          }
        });
      }
    );

    return prom;
  }

  function get(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getByLangId(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}&langId=${getCurrentLangId()}`, {
        params: _params,
      })
    );

    return prom;
  }

  function getPlaces(_id, _params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/${_id}/places`, {
        params: _params,
      })
    );

    return prom;
  }

  function getExcelLink(_id, _params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`${serviceUrl}/places/${_id}/excel`, {
        params: _params,
      })
    );

    return prom;
  }

  function genStageLogin(_id, _data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/${_id}/access`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function sendLogin(_id) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/${_id}/SendAccess`)
    );

    return prom;
  }

  function create(_data = {}, _params = {}) {
    
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function update(_data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.put(`${serviceUrl}`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function remove(_id, _params = {}) {
    const prom = fromPromise(
      apiV1.delete(`${serviceUrl}/${_id}`, {
        params: _params,
      })
    );

    return prom;
  }

  function addPlaces(_id, _data = {}, _params = {}) {
    const prom = fromPromise(
      apiV1.post(`${serviceUrl}/${_id}/places`, _data, {
        params: _params,
      })
    );

    return prom;
  }

  function getStages(_id, _params = {}) {
    _params.langId = getCurrentLangId();
    const prom = fromPromise(
      apiV1.get(`contests/${_id}/stages`, {
        params: _params,
      })
    );

    return prom;
  }


  return {
    getExcelLink,
    getPlaces,
    genStageLogin,
    getContests,
    getAll,
    get,
    getByLangId,
    create,
    update,
    remove,
    addPlaces,
    sendLogin,
    getStages
  };
});
