import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import loadable from '@loadable/component';
import { ToastContainer } from 'react-toastify';

import { RootStore, Storage, API_V1_REQ, Loading, persist, AUTH_KEY, SIGNIN_II_ROUTE } from 'Internal';

import './index.scss';

import * as serviceWorker from './serviceWorker';

const App = loadable(() => import('./App'));

const rootStore = RootStore.create(
  {
    authStore: {},
    appUIStore: {},
    contestStore: {},
    contestStageStore: {},
    contestSectionStore: {},
    contestApplicationStore: {},
    collectionStore: {},
    newsStore: {},
    templatesStore: {},
    fileStore: {},
    sponsorStore: {},
    anonymousStore: {},
    partnerStore: {},
    contestFormStore: {},
    languageStore: {},
    managerStore: {},
    tokensStore: {},
    policiesStore: {},
    applicationsManageStore: {},
    resultStore: {},
    medalsStore: {},
    reportStore: {},
  },
  {
    apiV1: API_V1_REQ,
    localStg: Storage,
  }
);

API_V1_REQ.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${rootStore.authStore.authData.accessToken}`;
    return config;
  },
  (err) => Promise.reject(err)
);

API_V1_REQ.interceptors.response.use(
  (resp) => resp,
  (err) => {
    const originalRequest = err.config;

    if (err.response?.status === 401 && originalRequest.url === '/token/refresh') {
      window.location.href = SIGNIN_II_ROUTE;
      return Promise.reject(err);
    }

    if (err.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = rootStore.authStore.authData.refreshToken;

      return rootStore.authStore
        .refreshToken({
          refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            API_V1_REQ.defaults.headers.common['Authorization'] = `Bearer ${rootStore.authStore.authData.accessToken}`;

            return API_V1_REQ(originalRequest);
          }
        })
        .catch(() => (window.location.href = SIGNIN_II_ROUTE));
    }
    return Promise.reject(err);
  }
);

persist(
  AUTH_KEY,
  rootStore.authStore,
  {
    storage: window.localStorage,
    jsonify: true,
  },
  {
    _authData: true,
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider stores={rootStore}>
      <App fallback={<Loading />} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
