import PropTypes from 'prop-types';
import { applySnapshot, onSnapshot, types } from 'mobx-state-tree';
import moment from 'moment';
import i18next from 'i18next';

import { clear, getItem, removeItem, setItem, LANGUAGES } from 'Internal';

export const Storage = {
  setItem,
  clear,
  getItem,
  removeItem,
};

export function genID() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

/**
 * Custom MST ISO8601 Date type
 */
export const CustomISO8601Date = types.custom({
  name: 'CustomISO8601Date',
  fromSnapshot(value) {
    return moment(value).toDate();
  },
  toSnapshot(value) {
    return moment(value).toDate();
  },
  isTargetType(value) {
    return moment(value).isValid();
  },
  getValidationMessage(value) {
    return `${value} is not valid ISO8601 format`;
  },
});

export const CustomPrice = types.custom({
  name: 'CustomPrice',
  fromSnapshot(val) {
    return val;
  },
  toSnapshot(val) {
    return val / 100;
  },
  isTargetType(val) {
    return typeof val === 'number';
  },
  getValidationMessage(val) {
    return `${val} is not instanced of Number`;
  },
});

export function storageAvailable(type) {
  const storage = window[type],
    x = '__storage_test__';
  try {
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
}

// Shim Element if needed (e.g. in Node environment)
const Element = typeof window === 'object' && (window.Element || function () {});

export function DOMElement(props, propName, componentName) {
  if (!(props[propName] instanceof Element)) {
    /* eslint-disable prefer-template */
    return new Error(
      'Invalid prop `' +
        propName +
        '` supplied to `' +
        componentName +
        '`. Expected prop to be an instance of Element. Validation failed.'
    );
    /* eslint-enable prefer-template */
  }
}

export const targetPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
  DOMElement,
  PropTypes.shape({ current: PropTypes.any }),
]);

export const tagPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    ])
  ),
]);

export const keyCodes = {
  esc: 27,
  space: 32,
  enter: 13,
  tab: 9,
  up: 38,
  down: 40,
  home: 36,
  end: 35,
  n: 78,
  p: 80,
};

export const persist = (name, store, options, schema = {}) => {
  let hydrated = false;

  let storage = options.storage;

  if (typeof localStorage !== 'undefined' && localStorage === storage) {
    storage = Storage;
  }
  onSnapshot(store, (_snapshot) => {
    if (!hydrated) {
      return;
    }
    const snapshot = { ..._snapshot };
    Object.keys(snapshot).forEach((key) => {
      if (!schema[key]) {
        delete snapshot[key];
      }
    });
    const data = !options.jsonify ? snapshot : JSON.stringify(snapshot);
    storage.setItem(name, data);
  });

  storage.getItem(name).then((data) => {
    if (data) {
      const snapshot = !options.jsonify ? data : JSON.parse(data);
      applySnapshot(store, snapshot);
      if (store.afterHydration && typeof store.afterHydration === 'function') {
        store.afterHydration();
      }
    }
    hydrated = true;
  });
};

export function encodeFileAsURL(file, callback) {
  const reader = new FileReader();
  reader.onloadend = function () {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

export function getCurrentLangId() {
  return LANGUAGES?.find((lang) => lang.code === i18next.language)?.id;
}

export function findAndReplaceStatus(id, arr, status) {
  const tempData = [...arr];
  const index = tempData.findIndex((el) => el.id === id);
  tempData[index] = { ...tempData[index], status };
  return tempData;
}
